export enum ActivationsManagementConfirmModalActionsEnum {
  Cancel = 'cancelled',
  Settle = 'settled',
}

export enum ShippingActivationSettlementStatusEnum {
  Cancel = 'cancelled',
  Settle = 'settled',
  NotSettled = 'not_settled',
}

export enum ShippingActivationSettlementStatusLabelEnum {
  Cancel = 'Cancelled',
  Settle = 'Settled',
  NotSettled = 'Pending',
}

export enum ActivationConfirmModalTitleEnum {
  Cancel = 'Are you sure about to cancel?',
  Settle = 'Are you sure about making a settlement?',
}

export enum ActivationConfirmModalDescriptionEnum {
  Cancel = 'You are about to make a cancellation for the selected activation',
  Settle = 'You are about to make a settlement for the selected activation',
  CancelBulk = 'You are about to make a bulk cancellation to all the selected activations rows',
  SettleBulk = 'Your are about to make a bulk settlement to all the selected activations rows',
}

export enum ActivationActionResponseMessageEnum {
  CancelSuccess = 'Your cancellation request was completed successfully',
  SettleSuccess = 'Your settlement request was completed successfully',
}

export enum ActivationStatusLabelEnum {
  Active = 'Active',
  NotActive = 'Not Active',
}

export enum ActivationStatusValueEnum {
  Active = 1,
  NotActive = 0,
}

export interface ActivationsConfirmModalStateInterface {
  id: string[] | null;
  isOpen: boolean;
  isLoading: boolean;
  title: string;
  description: string;
  type: ActivationsManagementConfirmModalActionsEnum | null;
  isBulk: boolean;
}

export interface ShippingActivationInterface {
  id: string;
  store_id: number;
  store_url: string;
  app_name: string;
  status: number;
  created_at: string;
  fees: number | null;
  settlement_status: ShippingActivationSettlementStatusEnum;
}

export interface ShippingActivationAppInterface {
  id: number;
  name: string;
}

export interface NumericFieldValueInterface {
  label: string;
  value: number;
}

export interface ShippingActivationsSearchParamsInterface {
  store: number | null;
  app?: number[];
  status: ActivationStatusValueEnum | null;
  settlement_status?: ShippingActivationSettlementStatusEnum[];
  created_at_from?: string;
  created_at_to?: string;
  page?: number;
  per_page?: number;
}

export interface ShippingActivationsSearchParamsStateInterface {
  store: number | null;
  app: number[];
  status: ActivationStatusValueEnum | null;
  settlementStatus: ShippingActivationSettlementStatusEnum[];
  createdAtFrom: string;
  createdAtTo: string;
  page: number;
  perPage: number;
}

export interface FormattedShippingActivationInterface {
  storeId: number;
  storeLink: string;
  appName: string;
  activationStatus: ActivationStatusLabelEnum;
  activationDate: string;
  zidFees: number;
  paymentStatus: ShippingActivationSettlementStatusLabelEnum;
}
