import BaseModel from '@/api/models/BaseModel';
import { LengthAwarePaginationInterface } from '@/types/global-types/pagination.type';
import {
  ShippingActivationAppInterface,
  ShippingActivationInterface,
} from '@/domains/finance-admin/types/shipping-activations.type';

export default class ShippingActivationsResponseModel extends BaseModel {
  public payload: {
    activations: ShippingActivationInterface[];
    apps: ShippingActivationAppInterface[];
    pagination: LengthAwarePaginationInterface;
  };

  constructor(args: any) {
    super(args);
    this.payload = args.payload;
  }
}
