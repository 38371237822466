import { onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import {
  FormattedShippingOrderInterface,
  NumericFieldValueInterface,
  OrderActionResponseMessageEnum,
  OrderConfirmModalDescriptionEnum,
  OrderConfirmModalTitleEnum,
  OrdersConfirmModalStateInterface,
  OrdersManagementConfirmModalActionsEnum,
  OrderStatusLabelEnum,
  OrderStatusValueEnum,
  ShippingOrderInterface,
  ShippingOrderSettlementStatusEnum,
  ShippingOrderSettlementStatusLabelEnum,
  ShippingOrdersSearchParamsStateInterface,
} from '@/domains/finance-admin/types/shipping-orders.type';
import { getShippingOrders, updateShippingOrderPaymentStatus } from '@/domains/finance-admin/api/shipping-orders';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { METABASE_SHIPPING_ORDERS_FINANCE } from '@/helpers/constantLinks';

const useShippingOrders = () => {
  const allStatusesList = [
    OrderStatusValueEnum.New,
    OrderStatusValueEnum.Preparing,
    OrderStatusValueEnum.Ready,
    OrderStatusValueEnum.InDelivery,
    OrderStatusValueEnum.Canceled,
    OrderStatusValueEnum.Completed,
  ];

  const allSettlementStatuses = [
    ShippingOrderSettlementStatusEnum.Settle,
    ShippingOrderSettlementStatusEnum.NotSettled,
    ShippingOrderSettlementStatusEnum.Cancel,
  ];

  const showFiltersBox = ref(false);
  const isLoadingOrdersList = ref(false);
  const totalPages = ref(0);
  const totalRecords = ref(0);
  const canBulkSettleOrCancel = ref(false);
  const currentPerPage = ref(30);

  const ordersList = reactive<Record<string, ShippingOrderInterface[]>>({ list: [] });
  const appsList = reactive<Record<string, NumericFieldValueInterface[]>>({ list: [] });
  const selectedAppsList = reactive<Record<string, NumericFieldValueInterface[]>>({ list: [] });
  const perPageList = reactive<Record<string, NumericFieldValueInterface[]>>({ list: [] });
  const selectedOrders = reactive<Record<string, ShippingOrderInterface[]>>({ list: [] });
  const tempSearchParamsState = reactive<ShippingOrdersSearchParamsStateInterface>({
    order: '',
    app: [],
    status: [],
    settlementStatus: [],
    createdAtFrom: '',
    createdAtTo: '',
    deliveredAtFrom: '',
    deliveredAtTo: '',
    page: 1,
    perPage: 30,
  });
  const searchParamsState = reactive<ShippingOrdersSearchParamsStateInterface>({
    order: '',
    app: [],
    status: [],
    settlementStatus: [],
    createdAtFrom: '',
    createdAtTo: '',
    deliveredAtFrom: '',
    deliveredAtTo: '',
    page: 1,
    perPage: 30,
  });

  const confirmModalState = reactive<OrdersConfirmModalStateInterface>({
    id: null,
    isOpen: false,
    isLoading: false,
    title: '',
    description: '',
    isBulk: false,
    type: null,
  });

  onBeforeMount(async () => {
    await loadShippingOrders();
  });

  watch(
    searchParamsState,
    async () => {
      await loadShippingOrders();
    },
    { deep: true },
  );

  watch(
    selectedOrders,
    () => {
      canBulkSettleOrCancel.value = canDoBulkAction();
    },
    { deep: true },
  );

  const formatDate = (date: string): string => {
    return date ? dayjs(date).format('DD/MM/YYYY') : '';
  };

  const loadShippingOrders = async () => {
    isLoadingOrdersList.value = true;
    ordersList.list = [];
    selectedOrders.list = [];
    const shippingOrdersList = await getShippingOrders({
      order: searchParamsState.order ?? null,
      app: searchParamsState.app,
      status: searchParamsState.status,
      settlement_status: searchParamsState.settlementStatus,
      created_at_from: formatDate(searchParamsState.createdAtFrom),
      created_at_to: formatDate(searchParamsState.createdAtTo),
      delivered_at_from: formatDate(searchParamsState.deliveredAtFrom),
      delivered_at_to: formatDate(searchParamsState.deliveredAtFrom),
      page: searchParamsState.page,
      per_page: searchParamsState.perPage,
    });
    if (shippingOrdersList instanceof ErrorModel || !shippingOrdersList.payload) {
      Vue.$toast.error('Could not load orders list');
      isLoadingOrdersList.value = false;
      return;
    }
    ordersList.list = shippingOrdersList.payload.orders;
    appsList.list = shippingOrdersList.payload.apps.map((app) => {
      return { label: app.name, value: app.id };
    });
    totalPages.value = shippingOrdersList.payload.pagination.last_page;
    totalRecords.value = shippingOrdersList.payload.pagination.total;
    setPerPageList();
    isLoadingOrdersList.value = false;
  };

  const toggleFiltersBox = () => {
    showFiltersBox.value = !showFiltersBox.value;
  };

  const onOrdersItemAction = (orderId: string[], action: OrdersManagementConfirmModalActionsEnum, isBulk: boolean) => {
    confirmModalState.id = orderId;
    confirmModalState.isOpen = true;
    confirmModalState.type = action;
    confirmModalState.isBulk = isBulk;
    confirmModalState.title =
      action === OrdersManagementConfirmModalActionsEnum.Settle
        ? OrderConfirmModalTitleEnum.Settle
        : OrderConfirmModalTitleEnum.Cancel;
    confirmModalState.description = getConfirmModalDescription(action, isBulk);
  };

  const resetConfirmModalState = () => {
    confirmModalState.id = null;
    confirmModalState.isOpen = false;
    confirmModalState.isLoading = false;
    confirmModalState.title = '';
    confirmModalState.description = '';
    confirmModalState.type = null;
    confirmModalState.isBulk = false;
  };

  const commitConfirmModalAction = async () => {
    confirmModalState.isLoading = true;
    if (confirmModalState.isBulk && !canDoBulkAction()) {
      Vue.$toast.error('This bulk action cannot be done on your current selection.');
      resetConfirmModalState();
      return;
    }
    if (!confirmModalState.id || !confirmModalState.type) {
      resetConfirmModalState();
      return;
    }
    const response = await updateShippingOrderPaymentStatus(confirmModalState.id, confirmModalState.type);
    if (response instanceof ErrorModel || response.status !== 'success') {
      Vue.$toast.error(`Couldn't complete your request!`);
      resetConfirmModalState();
      return;
    }
    const successMessage =
      confirmModalState.type == OrdersManagementConfirmModalActionsEnum.Cancel
        ? OrderActionResponseMessageEnum.CancelSuccess
        : OrderActionResponseMessageEnum.SettleSuccess;
    resetConfirmModalState();
    resetTargetPage();
    await loadShippingOrders();
    Vue.$toast.success(successMessage);
  };

  const getOrderStatusLabel = (status: OrderStatusValueEnum): OrderStatusLabelEnum => {
    switch (status) {
      case OrderStatusValueEnum.Canceled:
        return OrderStatusLabelEnum.Canceled;
      case OrderStatusValueEnum.Completed:
        return OrderStatusLabelEnum.Completed;
      case OrderStatusValueEnum.New:
        return OrderStatusLabelEnum.New;
      case OrderStatusValueEnum.Ready:
        return OrderStatusLabelEnum.Ready;
      case OrderStatusValueEnum.Preparing:
        return OrderStatusLabelEnum.Preparing;
      case OrderStatusValueEnum.InDelivery:
        return OrderStatusLabelEnum.InDelivery;
      default:
        return OrderStatusLabelEnum.New;
    }
  };

  const getOrderBadgeType = (status: OrderStatusValueEnum): string => {
    switch (status) {
      case OrderStatusValueEnum.Canceled:
        return 'danger';
      case OrderStatusValueEnum.Completed:
        return 'success';
      case OrderStatusValueEnum.New:
        return 'neutral';
      case OrderStatusValueEnum.Ready:
        return 'dark';
      case OrderStatusValueEnum.Preparing:
        return 'info';
      case OrderStatusValueEnum.InDelivery:
        return 'primary';
      default:
        return 'neutral';
    }
  };

  const getSettlementStatusLabel = (status: ShippingOrderSettlementStatusEnum) => {
    switch (status) {
      case ShippingOrderSettlementStatusEnum.Settle:
        return ShippingOrderSettlementStatusLabelEnum.Settle;
      case ShippingOrderSettlementStatusEnum.Cancel:
        return ShippingOrderSettlementStatusLabelEnum.Cancel;
      default:
        return ShippingOrderSettlementStatusLabelEnum.NotSettled;
    }
  };

  const getSettlementStatusBadgeType = (status: ShippingOrderSettlementStatusEnum): string => {
    switch (status) {
      case ShippingOrderSettlementStatusEnum.Settle:
        return 'success';
      case ShippingOrderSettlementStatusEnum.Cancel:
        return 'danger';
      default:
        return 'warning';
    }
  };

  const getConfirmModalDescription = (action: OrdersManagementConfirmModalActionsEnum, isBulk: boolean) => {
    if (!isBulk) {
      return action === OrdersManagementConfirmModalActionsEnum.Cancel
        ? OrderConfirmModalDescriptionEnum.Cancel
        : OrderConfirmModalDescriptionEnum.Settle;
    }

    return action === OrdersManagementConfirmModalActionsEnum.Cancel
      ? OrderConfirmModalDescriptionEnum.CancelBulk
      : OrderConfirmModalDescriptionEnum.SettleBulk;
  };

  const resetTargetPage = () => {
    searchParamsState.page = 1;
  };

  const isStatusSelected = (status: OrderStatusValueEnum): boolean => {
    return tempSearchParamsState.status.indexOf(status) !== -1;
  };

  const onStatusChange = (isChecked: boolean, checkedStatus: OrderStatusValueEnum) => {
    if (!isChecked) {
      tempSearchParamsState.status = tempSearchParamsState.status.filter((status) => {
        return status !== checkedStatus;
      });
      return;
    }
    if (!isStatusSelected(checkedStatus)) {
      tempSearchParamsState.status.push(checkedStatus);
    }
  };

  const isSelectedSettlementStatus = (status: ShippingOrderSettlementStatusEnum) => {
    return tempSearchParamsState.settlementStatus.indexOf(status) !== -1;
  };

  const onSettlementStatusChange = (isChecked: boolean, changedStatus: ShippingOrderSettlementStatusEnum) => {
    if (!isChecked) {
      tempSearchParamsState.settlementStatus = tempSearchParamsState.settlementStatus.filter((status) => {
        return status !== changedStatus;
      });
      return;
    }
    if (!isSelectedSettlementStatus(changedStatus)) {
      tempSearchParamsState.settlementStatus.push(changedStatus);
    }
  };

  const onSelectAllStatuses = (isChecked: boolean) => {
    if (!isChecked) {
      tempSearchParamsState.status = [];
      return;
    }
    tempSearchParamsState.status = [...allStatusesList];
  };

  const onSelectAllSettlementStatuses = (isChecked: boolean) => {
    if (!isChecked) {
      tempSearchParamsState.settlementStatus = [];
      return;
    }
    tempSearchParamsState.settlementStatus = [...allSettlementStatuses];
  };

  const onApplyFiltersClick = () => {
    switchSearchParamsState(searchParamsState, tempSearchParamsState);
    resetTargetPage();
    showFiltersBox.value = false;
  };

  const onCancelFiltersButtonClick = () => {
    showFiltersBox.value = false;
    switchSearchParamsState(tempSearchParamsState, searchParamsState);
  };

  const switchSearchParamsState = (
    fromState: ShippingOrdersSearchParamsStateInterface,
    toState: ShippingOrdersSearchParamsStateInterface,
  ) => {
    fromState.app = toState.app;
    fromState.status = [...toState.status];
    fromState.settlementStatus = [...toState.settlementStatus];
    fromState.createdAtFrom = toState.createdAtFrom;
    fromState.createdAtTo = toState.createdAtTo;
    fromState.deliveredAtFrom = toState.deliveredAtFrom;
    fromState.deliveredAtTo = toState.deliveredAtTo;
    selectedAppsList.list = appsList.list.filter((selectedApp) => {
      return toState.app.some((app) => app === selectedApp.value);
    });
  };

  const onSelectApp = (selectedApp: NumericFieldValueInterface) => {
    if (selectedAppsList.list.some((app) => app.value === selectedApp.value)) return;
    selectedAppsList.list.push(selectedApp);
    setSelectedAppsState();
  };

  const onUnSelectApp = (unselectedApp: NumericFieldValueInterface) => {
    selectedAppsList.list = selectedAppsList.list.filter((app) => app.value !== unselectedApp.value);
    setSelectedAppsState();
  };

  const setSelectedAppsState = () => {
    tempSearchParamsState.app = [...selectedAppsList.list.map((app) => app.value)];
  };

  const isSelectedApp = (appId: number): boolean => {
    return selectedAppsList.list.some((app) => app.value === appId);
  };

  const onResultPerPageChange = (perPage: NumericFieldValueInterface) => {
    searchParamsState.perPage = perPage.value;
    currentPerPage.value = perPage.value;
    resetTargetPage();
  };

  const setPerPageList = () => {
    perPageList.list = [];
    if (totalRecords.value <= 30) {
      perPageList.list.push({ label: totalRecords.value.toString(), value: totalRecords.value });
      currentPerPage.value = totalRecords.value;
      return;
    }

    for (let i = 30; i <= totalRecords.value; i += 10) {
      perPageList.list.push({ label: i.toString(), value: i });
      if (i >= 100) {
        break;
      }
    }
  };

  const getOrderById = (id: string) => {
    return ordersList.list.find((order: ShippingOrderInterface) => order.id === id) ?? null;
  };

  const isSelectedOrder = (id: string) => {
    return selectedOrders.list.some((order) => order.id === id);
  };

  const removeSelectedOrderById = (id: string) => {
    selectedOrders.list = selectedOrders.list.filter((order: ShippingOrderInterface) => order.id != id);
  };

  const onCheckboxAction = (isChecked: boolean, orderId: string) => {
    if (!isChecked) {
      removeSelectedOrderById(orderId);
      return;
    }
    if (isSelectedOrder(orderId)) {
      return;
    }
    const order = getOrderById(orderId);
    if (order) {
      selectedOrders.list.push(order);
    }
  };

  const getSelectedOrdersIds = (): string[] => {
    return selectedOrders.list.map((order) => order.id);
  };

  const onCheckAllAction = (isChecked: boolean) => {
    if (!isChecked) {
      selectedOrders.list = [];
      return;
    }
    selectedOrders.list = [...ordersList.list];
  };

  const canDoBulkAction = (): boolean => {
    if (!selectedOrders.list.length) {
      return false;
    }
    return !selectedOrders.list.some((order) => {
      return order.settlement_status !== ShippingOrderSettlementStatusEnum.NotSettled;
    });
  };

  const exportSelectedOrders = () => {
    if (!selectedOrders.list.length) {
      Vue.$toast.error('No selected orders to export!');
      return;
    }

    const formattedOrders: FormattedShippingOrderInterface[] = [];

    selectedOrders.list.forEach((order) => {
      formattedOrders.push({
        storeId: order.store_id,
        storeLink: order.store_url,
        appName: order.app_name,
        orderId: order.order_id,
        orderStatus: getOrderStatusLabel(order.order_status),
        creationDate: formatDate(order.created_at),
        deliveryDate: formatDate(order.delivered_at ?? ''),
        zidFees: order.zid_fees ?? 0,
        paymentStatus: getSettlementStatusLabel(order.settlement_status as ShippingOrderSettlementStatusEnum),
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedOrders);
    const new_workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'shipping-orders');
    XLSX.writeFile(new_workbook, `selected-shipping-orders-${dayjs().format('DD-MM-YYYY')}.xlsx`);
  };

  return {
    METABASE_SHIPPING_ORDERS_FINANCE,
    OrdersManagementConfirmModalActionsEnum,
    allStatusesList,
    allSettlementStatuses,
    OrderStatusValueEnum,
    ShippingOrderSettlementStatusEnum,
    showFiltersBox,
    isLoadingOrdersList,
    totalPages,
    totalRecords,
    confirmModalState,
    ordersList,
    appsList,
    selectedAppsList,
    searchParamsState,
    tempSearchParamsState,
    perPageList,
    selectedOrders,
    canBulkSettleOrCancel,
    currentPerPage,
    formatDate,
    toggleFiltersBox,
    resetConfirmModalState,
    onOrdersItemAction,
    getOrderStatusLabel,
    getSettlementStatusLabel,
    getOrderBadgeType,
    getSettlementStatusBadgeType,
    resetTargetPage,
    isStatusSelected,
    onStatusChange,
    onSelectAllStatuses,
    onSettlementStatusChange,
    isSelectedSettlementStatus,
    onSelectAllSettlementStatuses,
    onSelectApp,
    onUnSelectApp,
    isSelectedApp,
    onResultPerPageChange,
    onApplyFiltersClick,
    onCancelFiltersButtonClick,
    isSelectedOrder,
    onCheckboxAction,
    onCheckAllAction,
    getSelectedOrdersIds,
    exportSelectedOrders,
    commitConfirmModalAction,
  };
};

export default useShippingOrders;
