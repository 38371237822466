import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ShippingOrdersResponseModel from '@/domains/finance-admin/models/ShippingOrdersResponseModel';
import {
  OrdersManagementConfirmModalActionsEnum,
  ShippingOrdersSearchParamsInterface,
} from '@/domains/finance-admin/types/shipping-orders.type';
import BaseModel from '@/api/models/BaseModel';

const ENDPOINT_URL = '/market/finance-admin';

export const getShippingOrders = (
  params: ShippingOrdersSearchParamsInterface,
): Promise<ShippingOrdersResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/finance/shipping/orders`, { params: params })
    .then((response) => new ShippingOrdersResponseModel(response))
    .catch((error) => error);
};

export const updateShippingOrderPaymentStatus = (
  orders: string[],
  status: OrdersManagementConfirmModalActionsEnum,
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/finance/shipping/orders`, {
      orders: orders,
      status: status,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
