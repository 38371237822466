import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import BaseModel from '@/api/models/BaseModel';
import {
  ActivationsManagementConfirmModalActionsEnum,
  ShippingActivationsSearchParamsInterface,
} from '@/domains/finance-admin/types/shipping-activations.type';
import ShippingActivationsResponseModel from '@/domains/finance-admin/models/ShippingActivationsResponseModel';

const ENDPOINT_URL = '/market/finance-admin';

export const getShippingActivations = (
  params: ShippingActivationsSearchParamsInterface,
): Promise<ShippingActivationsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/finance/shipping/activations`, { params: params })
    .then((response) => new ShippingActivationsResponseModel(response))
    .catch((error) => error);
};

export const updateShippingActivationPaymentStatus = (
  activations: string[],
  status: ActivationsManagementConfirmModalActionsEnum,
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/finance/shipping/activations`, {
      activations: activations,
      status: status,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
