import BaseModel from '@/api/models/BaseModel';
import { ShippingOrderInterface, ShippingOrdersAppInterface } from '@/domains/finance-admin/types/shipping-orders.type';
import { LengthAwarePaginationInterface } from '@/types/global-types/pagination.type';

export default class ShippingOrdersResponseModel extends BaseModel {
  public payload: {
    orders: ShippingOrderInterface[];
    apps: ShippingOrdersAppInterface[];
    pagination: LengthAwarePaginationInterface;
  };

  constructor(args: any) {
    super(args);
    this.payload = args.payload;
  }
}
