import { onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import {
  ActivationActionResponseMessageEnum,
  ActivationConfirmModalDescriptionEnum,
  ActivationConfirmModalTitleEnum,
  ActivationsConfirmModalStateInterface,
  ActivationsManagementConfirmModalActionsEnum,
  ActivationStatusLabelEnum,
  ActivationStatusValueEnum,
  FormattedShippingActivationInterface,
  NumericFieldValueInterface,
  ShippingActivationInterface,
  ShippingActivationSettlementStatusEnum,
  ShippingActivationSettlementStatusLabelEnum,
  ShippingActivationsSearchParamsStateInterface,
} from '@/domains/finance-admin/types/shipping-activations.type';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import {
  getShippingActivations,
  updateShippingActivationPaymentStatus,
} from '@/domains/finance-admin/api/shipping-activations';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { METABASE_SHIPPING_ACTIVATIONS_FINANCE } from '@/helpers/constantLinks';

const useShippingActivations = () => {
  const allSettlementStatuses = [
    ShippingActivationSettlementStatusEnum.Settle,
    ShippingActivationSettlementStatusEnum.NotSettled,
    ShippingActivationSettlementStatusEnum.Cancel,
  ];
  const showFiltersBox = ref(false);
  const isLoadingActivationsList = ref(false);
  const totalPages = ref(0);
  const totalRecords = ref(0);
  const currentPerPage = ref(30);

  const confirmModalState = reactive<ActivationsConfirmModalStateInterface>({
    id: null,
    isOpen: false,
    isLoading: false,
    title: '',
    description: '',
    isBulk: false,
    type: null,
  });
  const activationsList = reactive<Record<string, ShippingActivationInterface[]>>({ list: [] });
  const appsList = reactive<Record<string, NumericFieldValueInterface[]>>({ list: [] });
  const selectedAppsList = reactive<Record<string, NumericFieldValueInterface[]>>({ list: [] });
  const perPageList = reactive<Record<string, NumericFieldValueInterface[]>>({ list: [] });
  const selectedActivations = reactive<Record<string, ShippingActivationInterface[]>>({ list: [] });
  const tempSearchParamsState = reactive<ShippingActivationsSearchParamsStateInterface>({
    store: null,
    app: [],
    status: null,
    settlementStatus: [],
    createdAtFrom: '',
    createdAtTo: '',
    page: 1,
    perPage: 30,
  });
  const searchParamsState = reactive<ShippingActivationsSearchParamsStateInterface>({
    store: null,
    app: [],
    status: null,
    settlementStatus: [],
    createdAtFrom: '',
    createdAtTo: '',
    page: 1,
    perPage: 30,
  });
  const selectedStatuses = reactive<Record<string, ActivationStatusValueEnum[]>>({ list: [] });

  onBeforeMount(async () => {
    await loadShippingActivations();
  });

  watch(
    searchParamsState,
    async () => {
      await loadShippingActivations();
    },
    { deep: true },
  );

  const formatDate = (date: string): string => {
    return date ? dayjs(date).format('DD/MM/YYYY') : '';
  };

  const loadShippingActivations = async () => {
    isLoadingActivationsList.value = true;
    activationsList.list = [];
    selectedActivations.list = [];
    const shippingActivationsList = await getShippingActivations({
      store: searchParamsState.store ?? null,
      app: searchParamsState.app,
      status: searchParamsState.status,
      settlement_status: searchParamsState.settlementStatus,
      created_at_from: formatDate(searchParamsState.createdAtFrom),
      created_at_to: formatDate(searchParamsState.createdAtTo),
      page: searchParamsState.page,
      per_page: searchParamsState.perPage,
    });

    if (shippingActivationsList instanceof ErrorModel || !shippingActivationsList.payload) {
      Vue.$toast.error('Could not load activations list');
      isLoadingActivationsList.value = false;
      return;
    }

    activationsList.list = shippingActivationsList.payload.activations;
    appsList.list = shippingActivationsList.payload.apps.map((app) => {
      return { label: app.name, value: app.id };
    });
    totalPages.value = shippingActivationsList.payload.pagination.last_page;
    totalRecords.value = shippingActivationsList.payload.pagination.total;
    setPerPageList();
    isLoadingActivationsList.value = false;
  };

  const toggleFiltersBox = () => {
    showFiltersBox.value = !showFiltersBox.value;
  };

  const onActivationsItemAction = (
    activationId: string[],
    action: ActivationsManagementConfirmModalActionsEnum,
    isBulk: boolean,
  ) => {
    confirmModalState.id = activationId;
    confirmModalState.isOpen = true;
    confirmModalState.type = action;
    confirmModalState.isBulk = isBulk;
    confirmModalState.title =
      action === ActivationsManagementConfirmModalActionsEnum.Settle
        ? ActivationConfirmModalTitleEnum.Settle
        : ActivationConfirmModalTitleEnum.Cancel;
    confirmModalState.description = getConfirmModalDescription(action, isBulk);
  };

  const resetConfirmModalState = () => {
    confirmModalState.id = null;
    confirmModalState.isOpen = false;
    confirmModalState.isLoading = false;
    confirmModalState.title = '';
    confirmModalState.description = '';
    confirmModalState.type = null;
    confirmModalState.isBulk = false;
  };

  const commitConfirmModalAction = async () => {
    confirmModalState.isLoading = true;
    if (confirmModalState.isBulk && !canDoBulkAction()) {
      Vue.$toast.error('This bulk action cannot be done on your current selection.');
      resetConfirmModalState();
      return;
    }
    if (!confirmModalState.id || !confirmModalState.type) {
      resetConfirmModalState();
      return;
    }
    const response = await updateShippingActivationPaymentStatus(confirmModalState.id, confirmModalState.type);
    if (response instanceof ErrorModel || response.status !== 'success') {
      Vue.$toast.error(`Couldn't complete your request!`);
      resetConfirmModalState();
      return;
    }
    const successMessage =
      confirmModalState.type == ActivationsManagementConfirmModalActionsEnum.Cancel
        ? ActivationActionResponseMessageEnum.CancelSuccess
        : ActivationActionResponseMessageEnum.SettleSuccess;
    resetConfirmModalState();
    resetTargetPage();
    await loadShippingActivations();
    Vue.$toast.success(successMessage);
  };

  const setPerPageList = () => {
    perPageList.list = [];
    if (totalRecords.value <= 30) {
      perPageList.list.push({ label: totalRecords.value.toString(), value: totalRecords.value });
      currentPerPage.value = totalRecords.value;
      return;
    }

    for (let i = 30; i <= totalRecords.value; i += 10) {
      perPageList.list.push({ label: i.toString(), value: i });
      if (i >= 100) {
        break;
      }
    }
  };

  const resetTargetPage = () => {
    searchParamsState.page = 1;
  };

  const switchSearchParamsState = (
    fromState: ShippingActivationsSearchParamsStateInterface,
    toState: ShippingActivationsSearchParamsStateInterface,
  ) => {
    fromState.app = [...toState.app];
    fromState.status = toState.status;
    fromState.settlementStatus = [...toState.settlementStatus];
    fromState.createdAtFrom = toState.createdAtFrom;
    fromState.createdAtTo = toState.createdAtTo;
    selectedAppsList.list = appsList.list.filter((selectedApp) => {
      return toState.app.some((app) => app === selectedApp.value);
    });
  };

  const onSelectApp = (selectedApp: NumericFieldValueInterface) => {
    if (selectedAppsList.list.some((app) => app.value === selectedApp.value)) return;
    selectedAppsList.list.push(selectedApp);
    setSelectedAppsState();
  };

  const onUnSelectApp = (unselectedApp: NumericFieldValueInterface) => {
    selectedAppsList.list = selectedAppsList.list.filter((app) => app.value !== unselectedApp.value);
    setSelectedAppsState();
  };

  const setSelectedAppsState = () => {
    tempSearchParamsState.app = [...selectedAppsList.list.map((app) => app.value)];
  };

  const isSelectedApp = (appId: number): boolean => {
    return selectedAppsList.list.some((app) => app.value === appId);
  };

  const onStatusChange = (isChecked: boolean, checkedStatus: ActivationStatusValueEnum) => {
    if (!isChecked) {
      selectedStatuses.list = selectedStatuses.list.filter((status) => status !== checkedStatus);
      setStatusParamState();
      return;
    }
    if (isSelectedStatus(checkedStatus)) {
      return;
    }
    selectedStatuses.list.push(checkedStatus);
    setStatusParamState();
  };

  const onSelectAllStatuses = (isChecked: boolean) => {
    if (!isChecked) {
      selectedStatuses.list = [];
      setStatusParamState();
      return;
    }
    selectedStatuses.list = [ActivationStatusValueEnum.Active, ActivationStatusValueEnum.NotActive];
    setStatusParamState();
  };

  const isSelectedStatus = (status: ActivationStatusValueEnum) => {
    return selectedStatuses.list.some((selectedStatus) => selectedStatus === status);
  };

  const setStatusParamState = () => {
    if (selectedStatuses.list.length === 0 || selectedStatuses.list.length === 2) {
      tempSearchParamsState.status = null;
      return;
    }
    tempSearchParamsState.status = selectedStatuses.list[0];
  };

  const isSelectedSettlementStatus = (status: ShippingActivationSettlementStatusEnum) => {
    return tempSearchParamsState.settlementStatus.indexOf(status) !== -1;
  };

  const onSettlementStatusChange = (isChecked: boolean, changedStatus: ShippingActivationSettlementStatusEnum) => {
    if (!isChecked) {
      tempSearchParamsState.settlementStatus = tempSearchParamsState.settlementStatus.filter((status) => {
        return status !== changedStatus;
      });
      return;
    }
    if (!isSelectedSettlementStatus(changedStatus)) {
      tempSearchParamsState.settlementStatus.push(changedStatus);
    }
  };

  const onSelectAllSettlementStatuses = (isChecked: boolean) => {
    if (!isChecked) {
      tempSearchParamsState.settlementStatus = [];
      return;
    }
    tempSearchParamsState.settlementStatus = [...allSettlementStatuses];
  };

  const onCancelFiltersButtonClick = () => {
    showFiltersBox.value = false;
    switchSearchParamsState(tempSearchParamsState, searchParamsState);
  };

  const onApplyFiltersClick = () => {
    switchSearchParamsState(searchParamsState, tempSearchParamsState);
    resetTargetPage();
    showFiltersBox.value = false;
  };

  const onResultPerPageChange = (perPage: NumericFieldValueInterface) => {
    searchParamsState.perPage = perPage.value;
    currentPerPage.value = perPage.value;
    resetTargetPage();
  };

  const exportSelectedActivations = () => {
    if (!selectedActivations.list.length) {
      Vue.$toast.error('No selected activations to export!');
      return;
    }

    const formattedActivations: FormattedShippingActivationInterface[] = [];

    selectedActivations.list.forEach((activation) => {
      formattedActivations.push({
        storeId: activation.store_id,
        storeLink: activation.store_url,
        appName: activation.app_name,
        activationStatus: getActivationStatusLabel(activation.status),
        activationDate: formatDate(activation.created_at),
        zidFees: activation.fees ?? 0,
        paymentStatus: getSettlementStatusLabel(activation.settlement_status as ShippingActivationSettlementStatusEnum),
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedActivations);
    const new_workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'shipping-activations');
    XLSX.writeFile(new_workbook, `selected-shipping-activations-${dayjs().format('DD-MM-YYYY')}.xlsx`);
  };

  const getActivationById = (id: string) => {
    return activationsList.list.find((activation: ShippingActivationInterface) => activation.id === id) ?? null;
  };

  const isSelectedActivation = (id: string) => {
    return selectedActivations.list.some((activation) => activation.id === id);
  };

  const removeSelectedActivationById = (id: string) => {
    selectedActivations.list = selectedActivations.list.filter(
      (activation: ShippingActivationInterface) => activation.id != id,
    );
  };

  const onCheckboxAction = (isChecked: boolean, activationId: string) => {
    if (!isChecked) {
      removeSelectedActivationById(activationId);
      return;
    }
    if (isSelectedActivation(activationId)) {
      return;
    }
    const activation = getActivationById(activationId);
    if (activation) {
      selectedActivations.list.push(activation);
    }
  };

  const getSelectedActivationsIds = (): string[] => {
    return selectedActivations.list.map((activation) => activation.id);
  };

  const onCheckAllAction = (isChecked: boolean) => {
    if (!isChecked) {
      selectedActivations.list = [];
      return;
    }
    selectedActivations.list = [...activationsList.list];
  };

  const canDoBulkAction = (): boolean => {
    if (!selectedActivations.list.length) {
      return false;
    }
    return !selectedActivations.list.some((activation) => {
      return activation.settlement_status !== ShippingActivationSettlementStatusEnum.NotSettled;
    });
  };

  const getConfirmModalDescription = (action: ActivationsManagementConfirmModalActionsEnum, isBulk: boolean) => {
    if (!isBulk) {
      return action === ActivationsManagementConfirmModalActionsEnum.Cancel
        ? ActivationConfirmModalDescriptionEnum.Cancel
        : ActivationConfirmModalDescriptionEnum.Settle;
    }

    return action === ActivationsManagementConfirmModalActionsEnum.Cancel
      ? ActivationConfirmModalDescriptionEnum.CancelBulk
      : ActivationConfirmModalDescriptionEnum.SettleBulk;
  };

  const getActivationStatusLabel = (status: ActivationStatusValueEnum): ActivationStatusLabelEnum => {
    return status === ActivationStatusValueEnum.Active
      ? ActivationStatusLabelEnum.Active
      : ActivationStatusLabelEnum.NotActive;
  };

  const getSettlementStatusLabel = (status: ShippingActivationSettlementStatusEnum) => {
    switch (status) {
      case ShippingActivationSettlementStatusEnum.Settle:
        return ShippingActivationSettlementStatusLabelEnum.Settle;
      case ShippingActivationSettlementStatusEnum.Cancel:
        return ShippingActivationSettlementStatusLabelEnum.Cancel;
      default:
        return ShippingActivationSettlementStatusLabelEnum.NotSettled;
    }
  };

  const getSettlementStatusBadgeType = (status: ShippingActivationSettlementStatusEnum): string => {
    switch (status) {
      case ShippingActivationSettlementStatusEnum.Settle:
        return 'success';
      case ShippingActivationSettlementStatusEnum.Cancel:
        return 'danger';
      default:
        return 'warning';
    }
  };

  return {
    METABASE_SHIPPING_ACTIVATIONS_FINANCE,
    allSettlementStatuses,
    ActivationsManagementConfirmModalActionsEnum,
    ActivationStatusValueEnum,
    ActivationStatusLabelEnum,
    ShippingActivationSettlementStatusEnum,
    showFiltersBox,
    isLoadingActivationsList,
    totalRecords,
    totalPages,
    confirmModalState,
    activationsList,
    searchParamsState,
    appsList,
    selectedAppsList,
    selectedStatuses,
    tempSearchParamsState,
    perPageList,
    selectedActivations,
    currentPerPage,
    toggleFiltersBox,
    resetConfirmModalState,
    onActivationsItemAction,
    getActivationStatusLabel,
    formatDate,
    getSettlementStatusLabel,
    getSettlementStatusBadgeType,
    resetTargetPage,
    onCancelFiltersButtonClick,
    onSelectApp,
    onUnSelectApp,
    isSelectedApp,
    onStatusChange,
    isSelectedStatus,
    onSelectAllStatuses,
    onSettlementStatusChange,
    isSelectedSettlementStatus,
    onSelectAllSettlementStatuses,
    onApplyFiltersClick,
    onResultPerPageChange,
    exportSelectedActivations,
    onCheckboxAction,
    onCheckAllAction,
    isSelectedActivation,
    getSelectedActivationsIds,
    canDoBulkAction,
    commitConfirmModalAction,
  };
};

export default useShippingActivations;
