import { defineComponent } from '@vue/composition-api';
import {
  ZidPageHeader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidBox,
  ZidInput,
  ZidButton,
  ZidCard,
  ZidCardHeader,
  ZidCardBody,
  ZidCombo,
  ZidComboItem,
  ZidCheckbox,
  ZidDatepicker,
  ZidTable,
  ZidTableHeader,
  ZidTableRow,
  ZidTableRowGroup,
  ZidTableCell,
  ZidBadge,
  ZidPagination,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidConfirmModal,
  ZidLoader,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import AwardIcon from '@/assets/icons/domains/finance-admin/AwardIcon.vue';
import LinkIcon from '@/assets/icons/domains/finance-admin/LinkIcon.vue';
import FilterIcon from '@/assets/icons/domains/finance-admin/FilterIcon.vue';
import SearchIcon from '@/assets/icons/domains/finance-admin/SearchIcon.vue';
import SettleIcon from '@/assets/icons/domains/finance-admin/SettleIcon.vue';
import RefundIcon from '@/assets/icons/domains/finance-admin/RefundIcon.vue';
import ExportIcon from '@/assets/icons/domains/finance-admin/ExportIcon.vue';
import CloseIcon from '@/assets/icons/domains/finance-admin/CloseIcon.vue';
import ExclamationMarkIcon from '@/assets/icons/domains/finance-admin/ExclamationMarkIcon.vue';
import useShippingActivations from '@/domains/finance-admin/helpers/shipping/useShippingActivations';

export default defineComponent({
  components: {
    ZidPageHeader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidBox,
    ZidInput,
    ZidButton,
    ZidCard,
    ZidCardHeader,
    ZidCardBody,
    ZidCombo,
    ZidComboItem,
    ZidCheckbox,
    ZidDatepicker,
    ZidTable,
    ZidTableHeader,
    ZidTableRow,
    ZidTableRowGroup,
    ZidTableCell,
    ZidBadge,
    ZidPagination,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidConfirmModal,
    ZidLoader,
    AwardIcon,
    BaseText,
    BaseIcon,
    LinkIcon,
    FilterIcon,
    SearchIcon,
    SettleIcon,
    RefundIcon,
    ExportIcon,
    CloseIcon,
    ExclamationMarkIcon,
  },
  setup() {
    return {
      ...useShippingActivations(),
    };
  },
});
