export enum OrdersManagementConfirmModalActionsEnum {
  Cancel = 'cancelled',
  Settle = 'settled',
}

export enum ShippingOrderSettlementStatusEnum {
  Cancel = 'cancelled',
  Settle = 'settled',
  NotSettled = 'not_settled',
}

export enum ShippingOrderSettlementStatusLabelEnum {
  Cancel = 'Cancelled',
  Settle = 'Settled',
  NotSettled = 'Pending',
}

export enum OrderConfirmModalTitleEnum {
  Cancel = 'Are you sure about to cancel?',
  Settle = 'Are you sure about making a settlement?',
}

export enum OrderConfirmModalDescriptionEnum {
  Cancel = 'You are about to cancel an order',
  Settle = 'You are about to make a settlement for an order',
  CancelBulk = 'You are about to make a bulk cancel to all the selected orders rows',
  SettleBulk = 'Your are about to make a bulk settlement to all the selected orders rows',
}
export enum OrderActionResponseMessageEnum {
  CancelSuccess = 'Your cancellation request was completed successfully',
  SettleSuccess = 'Your settlement request was completed successfully',
}

export enum OrderStatusLabelEnum {
  New = 'New',
  Preparing = 'Preparing',
  InDelivery = 'Delivery In Progress',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Ready = 'Ready',
}

export enum OrderStatusValueEnum {
  New = 1,
  Preparing = 2,
  InDelivery = 3,
  Completed = 5,
  Canceled = 7,
  Ready = 17,
}

export interface OrdersConfirmModalStateInterface {
  id: string[] | null;
  isOpen: boolean;
  isLoading: boolean;
  title: string;
  description: string;
  type: OrdersManagementConfirmModalActionsEnum | null;
  isBulk: boolean;
}

export interface ShippingOrderInterface {
  id: string;
  store_id: number;
  store_url: string;
  order_id: number;
  app_name: string;
  order_status: number;
  created_at: string;
  delivered_at: string | null;
  zid_fees: number | null;
  settlement_status: ShippingOrderSettlementStatusEnum;
}

export interface ShippingOrdersSearchParamsInterface {
  order?: string;
  app?: number[];
  status?: OrderStatusValueEnum[];
  settlement_status?: ShippingOrderSettlementStatusEnum[];
  created_at_from?: string;
  created_at_to?: string;
  delivered_at_from?: string;
  delivered_at_to?: string;
  page?: number;
  per_page?: number;
}

export interface ShippingOrdersSearchParamsStateInterface {
  order: string;
  app: number[];
  status: OrderStatusValueEnum[];
  settlementStatus: ShippingOrderSettlementStatusEnum[];
  createdAtFrom: string;
  createdAtTo: string;
  deliveredAtFrom: string;
  deliveredAtTo: string;
  page: number;
  perPage: number;
}

export interface ShippingOrdersAppInterface {
  id: number;
  name: string;
}

export interface NumericFieldValueInterface {
  label: string;
  value: number;
}

export interface FormattedShippingOrderInterface {
  storeId: number;
  storeLink: string;
  appName: string;
  orderId: number;
  orderStatus: OrderStatusLabelEnum;
  creationDate: string;
  deliveryDate: string;
  zidFees: number;
  paymentStatus: ShippingOrderSettlementStatusLabelEnum;
}
